import {DecimalPipe} from "@angular/common";
import {Injectable} from '@angular/core';
import {ImportFfmForm} from "@etop/state/shop/import-ffm";
import {UtilService} from 'apps/core/src/services/util.service';
import {AuthenticateStore} from '@etop/core';
import {TelegramApi} from "apps/core/src/apis/telegram-api.service";
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {ShopService} from './shop.service';
import {
  Connection,
  FeeType,
  MoneyTransactionShippingExternal,
  MoneyTransactionShop,
  MoneyTransactionTransfer,
  MONEY_TRANSACTION_RRULE,
  Order,
  ORDER_SOURCE,
  ShipmentPriceList,
  ShipmentService,
  Shop, Fulfillment, BankAccount, SHIPPING_FEE_SHOP_TYPE, Invitation, Tenant, User, Hotline, Ticket, MinShopBalanceSetting
} from "@etop/models";
import {AccountApi, AdminUserApi, EXTENSION_CHARGE_TYPE} from "@etop/api";
import {LocationQuery} from "@etop/state/location";
import {ActivatedRoute} from '@angular/router';
import List from "identical-list";
import {SubscriptionPlan} from "@etop/models/Subscription";
import { TicketLabelQuery } from "@etop/state/shop/ticket-labels";
import { ImportConsentForm } from "@etop/state/shop/import-consent";

interface ForceItem {
  id: string;
  type: string;
  expireTime: number;
}

// const PRODUCTION_DOMAINS = [
//   'shop.etop.vn',
//   'admin.etop.vn',
//   'next.etop.vn',
//   'admin-next.etop.vn',
//   'psx.etop.vn',
//   'pos.itopx.vn',
//   'shop.etelecom.vn',
//   'shop.topship.vn',
//   'pos.etop.vn',
//   'cs.etelecom.vn',
//   'seller.etop.vn',
//   'supply.etop.vn',
// ]

@Injectable({
  providedIn: "root"
})
export class TelegramService {
  groupProdIds = {
    confirm: '-1001324785000',
    cancel: '-239024220',
    newShop: '-298762499',
    updateShop: '-1001400752499',
    newAccount: '-270142863',
    personalMerchant: '-343016665',
    importOrder: '-386981050',
    force: {
      ghn: '-268371477',
      ghtk: '-222154225',
      vtpost: '-238517539'
    },
    mt_calendar: '-385331100',
    request_change_info: '-318111264',
    bank_account: '-368272965',
    company_info: '-328864179',
    partner_connect: '-377691588',
    shipnow_confirm: '-314350192',
    ahamove_verify: '-340800693',
    eTop_trading: '-276701871',
    paymentTrading: '-320986068',
    newTenantEtelecom: '-968419164',
    newExtension: '-502626234',
    newAccountTelecom: '-4577078188',
    recommend_trading_product: '-278827688',
    newSeller: '-394316902',
    adminUpdateFfmInfo: '-334518939',
    adminTransaction: "-1133823827",
    adminShipmentPrice: "-422157902",
    shopMoneyTransactionRrule: '-385331100',
    adminUpdateSales: "-1001727859722",
    adminNewSubscription: "-324306562",
    adminTenant: '-968419164',
    adminNewShippingCredit: '-551591520',
    adminNewTelecomCredit: '-594169757',
    userInvitation: '-595927040',
    userHotlineRequest : '-825295348',
    deleteShop: '-571626260',
    ticketNoti: '-1001698660100',
    ticketEtelecomSystem: '-701740973',
    adminUpdateUser: '-621090240',
    connectZCC: '-939942647',
    importConsent: '-882616392'
  };

  groupId = '-285363565';
  bugReportGroupId = '-185962908';

  onUpdateExpireTime = new Subject();
  forceList: List<ForceItem>;

  private __secretCode = 'fulfillment-data';

  constructor(
    private util: UtilService,
    private auth: AuthenticateStore,
    private number: DecimalPipe,
    private telegramApi: TelegramApi,
    private shopService: ShopService,
    private accountApi: AccountApi,
    private locationQuery: LocationQuery,
    private activatedRoute: ActivatedRoute,
    private ticketLabelQuery: TicketLabelQuery
  ) {
    this.getLocalForceList();
  }

  async newTenantMessage(tenant) {
    let fastBuyGroupId = this.groupProdIds.newTenantEtelecom;
    let msg = `
USER TẠO TENANT
-
<strong>User</strong>
${this.auth.snapshot.user.full_name} - ${this.auth.snapshot.user.phone}
-
<strong>Tenant</strong>
Name: ${tenant.name}
Domain: ${tenant.domain}
-
`
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendTicketNotiMessage(ticket: Ticket) {
    let fastBuyGroupId = this.groupProdIds.ticketNoti;
    let msg = `
Shop <strong>${ticket.from.name}</strong> gửi yêu cầu hỗ trợ

Nguồn: ${ticket.source}
Yêu cầu: ${ticket.title}

${ticket.description}
Xem chi tiết https://admin.etop.vn/tickets?code=${ticket.code}`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendTicketEtelecomSystemNotiMessage(ticket: Ticket) {
    let fastBuyGroupId = this.groupProdIds.ticketEtelecomSystem;
    let msg = `
<strong>YÊU CẦU HỖ TRỢ</strong>

Shop: <strong>${ticket.from.name}</strong>
Nguồn: ${ticket.source}
Loại yêu cầu: ${ticket.labels[0]?.name}

<strong>Tiêu đề:</strong> ${ticket.title}

<strong>Nội dung:</strong>
${ticket.description}

Xem chi tiết https://admin.etop.vn/tickets?code=${ticket.code}`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendTickeCommentNotiMessage(ticket: Ticket, message = '', photoUrl = '') {
    let fastBuyGroupId = this.groupProdIds.ticketNoti;
    let msg = `
Shop <strong>${ticket.from.name}</strong> gửi phản hồi cho ticket

Nguồn: ${ticket.source}
Nội dung yêu cầu: ${ticket.description}

Nội dung phản hồi: ${message}

Xem chi tiết https://admin.etop.vn/tickets?code=${ticket.code}`;

    if (ticket?.source.includes('telecom')) {
      fastBuyGroupId = this.groupProdIds.ticketEtelecomSystem;
    }

    try {
      if (photoUrl != null) {
        await this.telegramApi.sendPhoto(fastBuyGroupId, photoUrl, msg);
      } else {
        await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      }
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  getSurveyInfo() {
    let survey_data = JSON.parse(localStorage.getItem('survey'));
    let orders_per_day_text = survey_data.orders_per_day;
    let business_lines = survey_data.industry;
    return {
      orders_per_day_text,
      business_lines
    };
  }

  getSavedParams(): string {
    let _savedParams = JSON.parse(localStorage.getItem('savedParams'));
    let result = '';
    if (_savedParams && !this.util.isEmptyObject(_savedParams)) {
      for (let key in _savedParams) {
        result += `\n<strong>${key}</strong>: ${_savedParams[key]}`;
      }
    }
    return result;
  }

  async getPartnerString() {
    await this.shopService.getAuthorizedPartners();

    let partner_string = '';
    let partners = this.shopService.authorized_partners;
    if (partners && partners.length) {
      partner_string += `Partner: `;
      for (let i = 0; i < partners.length; i++) {
        if (i == 0) {
          partner_string += `<strong>${partners[i].name}</strong>`;
        } else {
          partner_string += `, <strong>${partners[i].name}</strong>`;
        }
      }
    }
    return partner_string;
  }

  checkREF(msg) {
    let refStr = `etop`;
    if (localStorage.getItem('REF')) {
      refStr = localStorage.getItem('REF');
    }
    msg += `
—————————
REF: ${refStr}`;
    return msg;
  }

  async sendOrderTelegramMessage(order: Order, action, reason?, fulfillment?) {
    if (!fulfillment) {
      return;
    }
    let fastBuyGroupId = action == 'confirm' ? this.groupProdIds.confirm : this.groupProdIds.cancel;

    let reason_line = action == 'confirm' ? '' : `<strong>Lý do: </strong>${reason}\n`;


    const {
      shipping_code,
      shipping_fee_shop,
      carrier,
      include_insurance,
      shipping_service_name,
      shipping_address,
      pickup_address
    } = fulfillment;

    const source_display = await this.orderSourceMap(
      order.source,
      order.partner_id
    );

    let partner_string = await this.getPartnerString();

    let order_line_string = '';
    for (let line of order.lines) {
      order_line_string += `${line.quantity} x ${
        line.product_name
      } - ${this.moneyFormat(line.retail_price)}\n`;
    }
    let fee_line_string = '';
    for (let line of order.fee_lines) {
      fee_line_string += `- ${line.name}: ${this.moneyFormat(
        line.amount
      )}\n`;
    }
    let msg = `
SHOP ĐÃ ${action == 'confirm' ? 'XÁC NHẬN' : 'HỦY'}!
https://admin.etop.vn/admin/fulfillments?code=${shipping_code}

Shop <strong>${this.auth.snapshot.shop.name}</strong>
${partner_string}
Nguồn <strong>${source_display}</strong>
${reason_line}

Tra cứu: ${Fulfillment.trackingLink(fulfillment) || ''}
<strong>${carrier.toUpperCase()}</strong> ${
      action === 'confirm'
        ? ` - ${shipping_service_name} - <strong>${this.moneyFormat(
        shipping_fee_shop
        )}</strong>`
        : ''
    }

☻ Gửi: <strong>${this.formatAddress(pickup_address)}</strong>
☻ Nhận: <strong>${this.formatAddress(shipping_address)}</strong>

Khách hàng: <strong>${shipping_address?.full_name}</strong>
Sản phẩm:
${order_line_string}
Khối lượng: <strong>${fulfillment.chargeable_weight}g</strong>
Bảo hiểm: <strong>${include_insurance ? 'Có' : 'Không'}</strong>
Tạm tính: <strong>${this.moneyFormat(order.basket_value)}</strong>
Phụ thu: <strong>${this.moneyFormat(
      order.fee_lines.reduce((a, b) => a + Number(b.amount), 0)
    )}</strong>
${fee_line_string}
Giảm giá: <strong>${this.moneyFormat(order.total_discount || 0)}</strong>

TỔNG TIỀN: <strong>${this.moneyFormat(order.total_amount)}</strong>
COD: <strong>${this.moneyFormat(fulfillment.cod_amount || 0)}</strong>

☻ Dự kiến lấy: <strong>${moment(fulfillment.estimated_pickup_at).format(
      'HH:mm DD-MM-YYYY'
    )}</strong>
☻ Dự kiến giao: <strong>${moment(fulfillment.estimated_delivery_at).format(
      'HH:mm DD-MM-YYYY'
    )}</strong>
`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendCancelSingleOrderMessage(order: Order, reason?, fulfillment?: Fulfillment) {
    const fastBuyGroupId = this.groupProdIds.cancel;

    const reason_line = `<strong>Lý do: </strong>${reason}\n`;
    const partner_string = await this.getPartnerString();

    let order_line_string = '';
    for (let line of order.lines) {
      order_line_string += `${line.quantity} x ${
        line.product_name
      } - ${this.moneyFormat(line.retail_price)}\n`;
    }
    let fee_line_string = '';
    for (let line of order.fee_lines) {
      fee_line_string += `- <i>${line.name}: ${this.moneyFormat(
        line.amount
      )}</i>\n`;
    }
    let msg = `
<a href="https://admin.etop.vn/admin/orders/${order.id}">#${
      order.code
    }</a>: SHOP ĐÃ HỦY${
      fulfillment ? `ĐƠN GIAO HÀNG #${fulfillment.shipping_code}` : ''
    }!

Shop <strong>${this.auth.snapshot.shop.name}</strong>
${partner_string}
Nguồn <strong>${order.source_display}</strong>
${reason_line}

Khách hàng: <strong>${order?.shipping_address?.full_name ||
    order?.customer_address?.full_name ||
    order?.customer?.full_name}</strong>
Sản phẩm:
${order_line_string}
Tạm tính: <strong>${this.moneyFormat(order.basket_value)}</strong>
Phụ thu: <strong>${this.moneyFormat(
      order.fee_lines.reduce((a, b) => a + Number(b.amount), 0)
    )}</strong>
${fee_line_string}
Giảm giá: <strong>${this.moneyFormat(order.total_discount || 0)}</strong>

TỔNG TIỀN: <strong>${this.moneyFormat(order.total_amount)}</strong>
`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendConfirmedMultiOrderMessage(orderCount: number, shopName) {
    const fastBuyGroupId = this.groupProdIds.importOrder;

    let partner_string = await this.getPartnerString();

    let msg = `
SHOP ĐÃ XÁC NHẬN ${orderCount} ĐƠN HÀNG
Shop: <strong>${shopName}</strong>
${partner_string}`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async importConsentMessage(consents: ImportConsentForm[]) {
    const fastBuyGroupId = this.groupProdIds.importConsent;

    const success = consents.filter(consent => consent.errors?.length == 0).length;
    const err = consents.filter(consent => consent.errors?.length).length;

    let msg = `
SHOP ĐÃ IMPORT GỬI YÊU CẦU GỌI

Chi tiết:
- Tổng số: <strong>${consents.length}</strong>
- Số dòng hợp lệ: <strong>${success}</strong>
- Số dòng lỗi: <strong>${err}</strong>

Shop: <strong>${this.auth.snapshot.shop.name} - ${this.auth.snapshot.shop.code}</strong>`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendConfirmedImportOrderMessage(orderCount: number, totalItem, totalBasketValue, totalFee, totalDiscount, totalAmount, shopCode, shopName) {
    if (orderCount == 0) return;

    const fastBuyGroupId = this.groupProdIds.importOrder;

    let partner_string = await this.getPartnerString();

    let msg = `
SHOP ĐÃ IMPORT ${orderCount} ĐƠN HÀNG
Shop <strong>${shopCode} - ${shopName}</strong>
+ Số lượng sản phẩm: ${totalItem}
+ Tổng giá trị hàng hóa: ${this.moneyFormat(totalBasketValue)}
+ Tổng Phụ thu: ${this.moneyFormat(totalFee)}
+ Tổng Giảm giá: ${this.moneyFormat(totalDiscount)}
+ Tổng Khách phải trả: ${this.moneyFormat(totalAmount)}
${partner_string}`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendCancelMultiOrderMessage(orderCount: number, totalItem, totalBasketValue, totalFee, totalDiscount, totalAmount, shopCode, shopName) {

    const fastBuyGroupId = this.groupProdIds.cancel;

    let partner_string = await this.getPartnerString();

    let msg = `
SHOP ĐÃ HỦY ${orderCount} ĐƠN HÀNG
Shop <strong>${shopCode} - ${shopName}</strong>
+ Số lượng sản phẩm: ${totalItem}
+ Tổng giá trị hàng hóa: ${this.moneyFormat(totalBasketValue)}
+ Tổng Phụ thu: ${this.moneyFormat(totalFee)}
+ Tổng Giảm giá: ${this.moneyFormat(totalDiscount)}
+ Tổng Khách phải trả: ${this.moneyFormat(totalAmount)}
${partner_string}`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async sendCancelMultiFulfillmentMessage(fulfillmentCount: number, shopName) {

    const fastBuyGroupId = this.groupProdIds.cancel;

    let partner_string = await this.getPartnerString();

    let msg = `
SHOP ĐÃ HỦY ${fulfillmentCount} ĐƠN GIAO HÀNG
Shop <strong>${shopName}</strong>
${partner_string}`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  moneyFormat(money) {
    if (money === null || money === undefined) {
      return '--';
    }
    return money.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + 'đ';
  }

  formatAddress(address) {
    if (!address) {
      return '';
    }
    let res = '';

    const {address1, ward, district, province} = address;
    res += address1 ? address1 + ', ' : '';
    res += ward ? ward + ', ' : '';
    res += district ? district + ', ' : '';
    res += province || '';

    return res;
  }

  async personalMerchantMessage(signupData) {
    const fastBuyGroupId = this.groupProdIds.personalMerchant;
    let msg = `
<strong>Khách hàng cá nhân</strong>
Họ tên: <strong>${signupData?.full_name} </strong>
Số điện thoại: ${signupData?.phone}
Email: ${signupData.email}
—————————`;

    msg = this.checkREF(msg);
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async newOrderTrading(order, data) {
    const user = this.auth.snapshot.user;
    const shop = this.auth.snapshot.shop;
    const fastBuyGroupId = this.groupProdIds.eTop_trading;

    let msg = `
<strong>ĐƠN HÀNG MỚI ETOP TRADING!</strong>

Sản phẩm: ${order.lines[0].product_name}
Mã: ${order.lines[0].code}
Giá: ${this.moneyFormat(order.lines[0].retail_price)}
---
Đơn hàng: ${order.code}
Số lượng : ${order.total_items}
Giá trị: ${this.moneyFormat(order.total_amount)}
---
Người nhận:
${order?.shipping_address?.full_name} - ${order?.shipping_address?.phone}
${order.shipping_address.address1}, ${order.shipping_address.ward}, ${order.shipping_address.district}, ${order.shipping_address.province}
---`
    if (data.affCode) {
      msg += `
Mã giới thiệu: ${data.affCode || 'Không có mã giới thiệu'}
Giá trị eTop tặng shop: ${this.moneyFormat(data.promotion)}
Giá trị Seller tặng shop: ${this.moneyFormat(data.sellCashback)}
Tổng cashback: ${this.moneyFormat(data.totalCashback)}
---`;
    }
    msg += `
SHOP
${shop?.name} - ${shop?.phone}

User:
${user?.full_name} - ${user?.phone}
${user.email}
---
SELLER
seller_name - ${data.affCode || 'seller_phone'}
User:
user_name - user_phone
user_email
---
CUSTOM FIELD`;
    if (order.lines[0].meta_fields) {
      let msg_meta = '';
      for (const meta of order.lines[0].meta_fields) {
        msg_meta += `
${meta.name} : ${meta.value}`;
        msg += msg_meta;
      }
    }

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async recommendTrading(shop, user, product, quantity) {
    const fastBuyGroupId = this.groupProdIds.recommend_trading_product;
    let msg = `
SHOP <strong>${shop.name.toUpperCase()}</strong> ĐẶT HÀNG
${product.name}
Mã: <strong>${product.code}</strong>
Giá: <strong>${this.moneyFormat(product.variants[0].retail_price)}</strong>
---
Số lượng: <strong>${quantity}</strong>
Tổng cộng: <strong>${this.moneyFormat(quantity * product.variants[0].retail_price)}</strong>
---
SHOP
${shop.name} - ${shop.phone}`;
    if (shop.ship_from_address_id != 0) {
      msg += `
${shop.address.address1}, ${shop.address.ward}, ${shop.address.district}, ${shop.address.province}
`;
    }
    msg +=
      `User
${user.full_name} - ${user.phone}
---`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

  async newMerchantMessage(account, device?) {
    const fastBuyGroupId = this.groupProdIds.newAccount;
    let msg = `
<strong>ĐĂNG KÝ TÀI KHOẢN!</strong>
Họ tên: <strong>${account?.full_name} </strong>
Số điện thoại: ${account?.phone}
Email: ${account.email}`;
    if (device) {
      msg += `
—————————
Device: ${device.manufacturer} - ${device.model} - ${device.operatingSystem}  ${device.osVersion}
App version: ${device.appVersion}`;
    }

    msg = this.checkREF(msg);
    if (this.getSavedParams().length) {
      msg += `
—————————
Params:${this.getSavedParams()}`;
    }
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async newTradingPayment(order) {
    const user = this.auth.snapshot.user;
    const shop = this.auth.snapshot.shop;
    const fastBuyGroupId = this.groupProdIds.paymentTrading;

    let msg = `
<strong>THANH TOÁN THÀNH CÔNG ETOP TRADING!</strong>

Giá trị: <strong>${this.moneyFormat(order.total_amount)}</strong>
Thời gian: <strong>${moment().format('DD/MM/YYYY HH:mm')}</strong>
---
Mã đơn hàng: ${order.code}
Sản phẩm: ${order.lines[0].product_name}
Giá trị đơn hàng: ${this.moneyFormat(order.total_amount)}
Số lượng sản phẩm: ${order.total_items}
Tạo đơn lúc: ${moment(order.created_at).format('DD/MM/YYYY HH:mm')}
---
SHOP
${shop.name} - ${shop?.phone}
User:
${user?.full_name} - ${user?.phone}
${user.email}
---
CUSTOM FIELD`;
    if (order.lines[0].meta_fields) {
      let msg_meta = '';
      for (const meta of order.lines[0].meta_fields) {
        msg_meta += `
${meta.name} : ${meta.value}`;
        msg += msg_meta;
      }
    }

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }


  async newShopMessage(user, shop, address, surveyData?, device?) {
    const fastBuyGroupId = this.groupProdIds.newShop;
    let msg = `
<strong>ĐĂNG KÝ CỬA HÀNG!</strong>
Tên cửa hàng: <strong>${shop.name}</strong>
Số điện thoại: ${shop?.phone}
Website: ${shop.website_url}
Email: ${shop.email}
Địa chỉ: ${address}
—————————
Thuộc tài khoản: <strong>${user?.full_name}</strong>
Số điện thoại: ${user?.phone}
Email: ${user.email}
—————————
${this.getSurveyInfo().orders_per_day_text}
Ngành hàng kinh doanh: ${this.getSurveyInfo().business_lines}`;
    if (surveyData.reAnswer) {
      let surveyStr = `\n<strong>KH cá nhân thay đổi câu trả lời</strong>`;
      msg += surveyStr;
    }
    if (device) {
      msg += `
—————————
Device: ${device.manufacturer} - ${device.model} - ${device.operatingSystem}  ${
        device.osVersion
      }
App version: ${device.appVersion}`;
    }
    msg = this.checkREF(msg);
    if (this.getSavedParams().length) {
      msg += `
—————————
Params:${this.getSavedParams()}`;
    }

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateShopMessage(user, shop, address) {
    const fastBuyGroupId = this.groupProdIds.updateShop;
    const msg = `
<strong>CẬP NHẬT THÔNG TIN CỬA HÀNG!</strong>

Tên cửa hàng: <strong>${shop.name}</strong>
Số điện thoại: ${shop.phone}
Website: ${shop.website_url}
Email: ${shop.email}
Địa chỉ: ${address}
—————————
Thuộc tài khoản: <strong>${user?.full_name}</strong>
Số điện thoại: ${user?.phone}
Email: ${user.email}
    `;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateMTCalendar(shop, calendar: string) {
    const fastBuyGroupId = this.groupProdIds.mt_calendar;
    const msg = `<strong>${shop.name}</strong> - ${calendar}`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateCompanyInfo(shop, company_info: any) {
    const fastBuyGroupId = this.groupProdIds.company_info;
    const msg = `
CẬP NHẬT THÔNG TIN DOANH NGHIỆP

Shop: <strong>${shop.name}</strong>
Tên doanh nghiệp: ${company_info.name}
Mã số thuế: ${company_info.tax_code}
Địa chỉ trụ sở: ${company_info.address}
—————————
Người đại diện pháp luật
Tên: ${company_info.legal_representative.name}
Vị trí: ${company_info.legal_representative.position}
Số điện thoại: ${company_info?.legal_representative?.phone}
Email: ${company_info.legal_representative.email}`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateBankAccount(shop, bank_account: BankAccount) {
    const fastBuyGroupId = this.groupProdIds.bank_account;
    const msg = `
CẬP NHẬT THÔNG TIN NGÂN HÀNG

Shop: <strong>${shop.name}</strong>
Ngân hàng: ${bank_account.name}
Chi nhánh: ${bank_account.branch}
Tỉnh/thành: ${bank_account.province}
Tên chủ tài khoản: ${bank_account.account_name}
Số tài khoản: ${bank_account.account_number}`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async partnerConnect(
    shop_name: string,
    partner_name: string,
    redirect_url?: string
  ) {
    const fastBuyGroupId = this.groupProdIds.partner_connect;
    let msg = `<strong>${shop_name}</strong> liên kết <strong>${partner_name}</strong>`;
    if (redirect_url) {
      msg = `<strong>${shop_name}</strong> tạo tài khoản <strong>${partner_name}</strong> (truy cập link tạo tài khoản)`;
    }
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async shipnowConfirm(message_data) {
    const fastBuyGroupId = this.groupProdIds.shipnow_confirm;
    const shop = this.auth.snapshot.accounts.find(
      a => a.id == message_data.shop_id
    ).shop;

    const ward = this.locationQuery.getWard(message_data.pickup_address.ward_code);
    const district = this.locationQuery.getDistrict(message_data.pickup_address.district_code);
    const province = this.locationQuery.getProvince(message_data.pickup_address.province_code);

    let msg = `GIAO TỨC THÌ
-
<strong>Shop ${shop.name}</strong>
-
<strong>AHAMOVE</strong> - ${message_data.shipping_service_name} ${
      message_data.shipping_service_description
        ? `(${message_data.shipping_service_description})`
        : ''
    }
<strong>${moment(message_data.created_at).format('HH:mm DD/MM/YYYY')}</strong>
-
Phí: <strong>${this.util.formatCurrency(
      message_data.shipping_service_fee
    )}đ</strong>
Thu hộ: <strong>${this.util.formatCurrency(
      message_data.value_info.cod_amount
    )}đ</strong>
-
<strong>LẤY:</strong>
<strong>►[0]</strong> Shop ${shop.name} - ${
      message_data?.pickup_address?.full_name
    } - ${message_data?.pickup_address?.phone}
${message_data.pickup_address.address1}${ward ? `, ${ward.name}` : ''}${
      district ? `, ${district.name}` : ''
    }${province ? `, ${province.name}` : ''}

<strong>GIAO:</strong>`;
    let delivery_points_message = '';
    for (let i = 0; i < message_data.delivery_points.length; i++) {
      const order = message_data.delivery_points[i];

      let order_lines_message = '';
      for (let j = 0; j < order.lines.length; j++) {
        const line = order.lines[j];
        if (j < order.lines.length - 1) {
          order_lines_message += `${line.product_name} x ${line.quantity},`;
        } else {
          order_lines_message += `${line.product_name} x ${line.quantity}`;
        }
      }

      let _ward = this.locationQuery.getWard(order.shipping_address.ward_code);
      let _district = this.locationQuery.getDistrict(order.shipping_address.district_code);
      let _province = this.locationQuery.getProvince(order.shipping_address.province_code);

      delivery_points_message += `
<strong>►[${i + 1}] #${order.order_id}</strong>${
        order_lines_message ? ` - ${order_lines_message}` : ''
      } - <strong>${this.util.formatCurrency(
        order.value_info.cod_amount
      )}đ</strong>
<strong>${order?.shipping_address?.full_name} - ${
        order?.shipping_address?.phone
      }</strong>
<em>${order.shipping_address.address1}${_ward ? `, ${_ward.name}` : ''}${
        _district ? `, ${_district.name}` : ''
      }${_province ? `, ${_province.name}` : ''}</em>
<em>${order.shipping_note}</em>`;
    }
    msg += delivery_points_message;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async ahamoveVerify(type, shop_owner_data?, shop_data?) {
    const user = this.auth.snapshot.user;
    const fastBuyGroupId = this.groupProdIds.ahamove_verify;
    const type_map = {
      create: {
        name: 'CREATE ACCOUNT',
        icon: '⚪️'
      },
      submit_verify_message: {
        name: 'SUBMIT VERIFY IMAGE',
        icon: '⚫️'
      },
      verify_success: {
        name: 'VERIFY SUCCESSFULLY',
        icon: '🔵'
      },
      verify_failed: {
        name: 'VERIFY FAILED (over 48h)',
        icon: '🔴'
      }
    };

    let msg = `${type_map[type].icon} ${type_map[type].name}

User <strong>${user?.full_name} - ${user?.phone}</strong>
Shop `;
    let shop_message = '';
    const accounts = this.auth.snapshot.accounts;
    for (let i = 0; i < accounts.length; i++) {
      const shop = accounts[i].shop;
      if (i < accounts.length - 1) {
        shop_message += `<strong>${shop.name}, </strong>`;
      } else {
        shop_message += `<strong>${shop.name}</strong>`;
      }
    }

    msg += `${shop_message}\n`;

    if (shop_owner_data && !this.util.isEmptyObject(shop_owner_data)) {
      msg += '\n- XÁC THỰC CHỦ SHOP:';
      for (let key in shop_owner_data) {
        msg += `
${key}: ${shop_owner_data[key] ? 'OK' : '-'}`;
      }
    }

    if (shop_data && !this.util.isEmptyObject(shop_data)) {
      msg += '\n\n- XÁC THỰC SHOP:';
      for (let key in shop_data) {
        msg += `
${key}: ${shop_data[key] && shop_data[key].length ? 'OK' : '-'}`;
      }
    }

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async importFfmMessage(importFfms: ImportFfmForm[]) {
    const fastBuyGroupId = this.groupProdIds.importOrder;

    const totalCodAmount = importFfms?.reduce((a, b) => a + b?.cod_amount, 0);
    const totalShippingFee = importFfms?.reduce((a, b) => a + b?.shipping_service_fee, 0);
    const totalWeight = importFfms?.reduce((a, b) => a + b?.total_weight, 0);

    let msg = `
SHOP ĐÃ IMPORT ${importFfms.length} ĐƠN GIAO HÀNG
NVC: <strong>${importFfms[0].shipping_carrier_name}</strong>

Tổng:
- Thu hộ: <strong>${this.number.transform(totalCodAmount)}đ</strong>
- Cước phí: <strong>${this.number.transform(totalShippingFee)}đ</strong>
- Khối lượng: <strong>${this.number.transform(totalWeight)}g</strong>

Shop: <strong>${this.auth.snapshot.shop.name} - ${this.auth.snapshot.shop.code}</strong>`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  bugReport(content, images) {
    if (images.length) {
      return Promise.all(
        images.map((image, index) => {
          let msg =
            index == 0
              ? `
Lỗi: <strong>${content}</strong>

Hình ảnh mô tả:
<a href="${image}">⁠</a>
          `
              : `
<a href="${image}">⁠</a>
            `;

          return this.telegramApi.sendMessage(this.bugReportGroupId, msg);
        })
      );
    } else {
      let msg = `
Lỗi: <strong>${content}</strong>`;
      return this.telegramApi.sendMessage(this.bugReportGroupId, msg);
    }
  }

  bugTracking(api, body, response) {
    const msg = `
API: ${api}
Body: ${JSON.stringify(body, undefined, 2)}
Response: ${JSON.stringify(response, undefined, 2)}`;
    debug.log(msg);
    this.telegramApi.sendMessage(this.groupId, msg, false);
  }

  async newAccountTelecom(account) {
    const invitation_token = this.activatedRoute.snapshot.queryParamMap.get('invitation');
    const fastBuyGroupId = this.groupProdIds.newAccountTelecom;
    let msg = `
<strong>ĐĂNG KÝ TÀI KHOẢN!</strong>

Họ tên: <strong>${account.full_name} </strong>
Số điện thoại: <strong>${account.phone}</strong>
Email: ${account.email}
`;
    if (invitation_token) {
      msg += `
—————————
Nhân viên`;
    } else {
      msg += `
—————————
Chủ shop: <strong>${account?.shop?.code} - ${account?.shop_name}</strong>`;
    }
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async extendExtension(
    subscription: SubscriptionPlan, 
    extension_number: number, 
    staff_name: string, 
    hotline: Hotline) {
    const fastBuyGroupId = this.groupProdIds.newExtension;
    let msg = `
<strong>USER GIA HẠN EXTENSION</strong>
-
<strong>Subscription</strong>
${subscription.name} - ${this.number.transform(subscription.price)} - Số dư
-
<strong>Extension</strong>
${extension_number} → ${staff_name}
-
<strong>User - Shop</strong>
${this.auth.snapshot.user.full_name} - ${this.auth.snapshot.user.phone}
Shop ${this.auth.snapshot.shop.code} - ${this.auth.snapshot.shop.name}
-
<strong>Hotline</strong>
${hotline.hotline}`;
    try{
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async newExtension(
    subscription: SubscriptionPlan, 
    extension_number: number, 
    staff_name: string, 
    hotline: Hotline) {
    const fastBuyGroupId = this.groupProdIds.newExtension;
    let msg = `
<strong>USER TẠO EXTENSION</strong>
-
<strong>Subscription</strong>
${subscription.name} - ${this.number.transform(subscription.price)} - Số dư
-
<strong>Extension</strong>
${extension_number} → ${staff_name}
-
<strong>User - Shop</strong>
${this.auth.snapshot.user.full_name} - ${this.auth.snapshot.user.phone}
Shop ${this.auth.snapshot.shop.code} - ${this.auth.snapshot.shop.name}
-
<strong>Hotline</strong>
${hotline.hotline}`;
    try{
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async forceMessage(request: any, fulfillment?: Fulfillment) {
    const {
      shipping_address, pickup_address,
      shipping_service_code, shipping_provider, shipping_code,
      estimated_delivery_at, estimated_pickup_at
    } = fulfillment;

    let account = 'unknown';
    let login = '';
    switch (shipping_provider) {
      case 'ghn':
        if (shipping_service_code.charAt(0) === 'D') {
          account = 'TK 300 gr';
          login = '0938222489';
        } else if (shipping_service_code.charAt(0) === 'E') {
          account = 'TK 500 gr';
          login = '0976358769';
        }
        break;
      case 'ghtk':
        if (shipping_service_code.charAt(1) === 'D') {
          account = 'TK Default';
          login = 'giang2224@gmail.com';
        } else if (shipping_service_code.charAt(2) === 'S') {
          account = 'TK Đồng giá';
          login = 'giang@etop.vn';
        }
        break;
      default:
        account = 'vtpost';
    }

    let title = 'giao';
    let expected = moment(estimated_delivery_at).format('HH:mm DD/MM');
    let diff = moment(moment.now()).diff(moment(estimated_delivery_at), 'hours');

    if (request.code === 'force-picking') {
      title = 'lấy';
      expected = `trước ${moment(estimated_pickup_at).format('HH:mm DD/MM')}`;
      diff = moment(moment.now()).diff(moment(estimated_pickup_at), 'hours');
    }

    let fastBuyGroupId = this.groupProdIds.force[shipping_provider];

    const delay = moment.duration(diff, 'hours').humanize();
    if (request.code.split('-')[0] != 'force') {
      fastBuyGroupId = this.groupProdIds.request_change_info;
    }

    let msg = `
<strong>${shipping_code}</strong> - ${request.title.toUpperCase()}
--`;
    if (request.value) {
      msg += `
${request.old_value} >> <strong>${request.value}</strong>`;
    }
    msg += `
Lý do: ${request.reason}
--
${shipping_provider.toUpperCase()} ${account}
${login ? `Login <strong>${login}</strong>` : ''}
--
KH: ${shipping_address?.full_name}
SĐT: ${shipping_address?.phone}
Tạo lúc: ${moment(fulfillment.created_at).format('HH:mm DD/MM')}
Dự kiến ${title.toLowerCase()}: <strong>${expected}</strong>
${diff > 0 ? `Trễ: <strong>${delay}</strong>` : 'Chưa trễ'}
Lấy: ${pickup_address.province}
Nhận: ${shipping_address.province}

Shop: <strong>${fulfillment.shop?.name}</strong> - ${fulfillment.shop?.phone}`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      if (
        request.code == 'force-picking' ||
        request.code == 'force-delivering'
      ) {
        this.addForceItem(shipping_code, request.code);
        this.onUpdateExpireTime.next(
          this.checkStatusForceItem(shipping_code)
        );
      }
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.error('ERROR SEND MESSAGE', e);
    }
  }

  async newSellerMessage(account) {
    let fastBuyGroupId = this.groupProdIds.newSeller;
    let msg = `
<strong>ĐĂNG KÝ TÀI KHOẢN SELLER!</strong>

Họ tên: <strong>${account.full_name} </strong>
Số điện thoại: ${account.phone}
Email: ${account.email}`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async orderSourceMap(source, partner_id) {
    if (partner_id != '0' && ['etop_cmx', 'api'].indexOf(source) != -1) {
      const _partner = await this.accountApi.getPublicPartnerInfo(partner_id);
      return _partner
        ? `${ORDER_SOURCE[source]} - ${_partner.name}`
        : ORDER_SOURCE[source];
    }
    return source ? ORDER_SOURCE[source] : 'Không xác định';
  }

  checkStatusForceItem(shipping_code: string) {
    const item = this.forceList.get(shipping_code);
    if (!item) {
      return false;
    }
    const now = moment.now();
    return item.expireTime > now;
  }

  getLocalForceList() {
    const data: any = localStorage.getItem(this.__secretCode);
    const list = data ? JSON.parse(data).forceList : [];
    const now = moment.now();
    this.forceList = new List(list.filter(i => i.expireTime > now));
  }

  addForceItem(shipping_code, type) {
    const expireTime = moment(moment.now())
      .add(6, 'hours')
      .valueOf();
    this.forceList.add({
      id: shipping_code,
      type,
      expireTime
    });
    this.updateLocalForceList();
  }

  updateLocalForceList() {
    const data = {forceList: this.forceList.asArray()};
    localStorage.setItem(this.__secretCode, JSON.stringify(data));
  }

  async acceptInvitation(invitation: Invitation) {
    const fastBuyGroupId = this.groupProdIds.userInvitation;
    const msg = `
CHẤP NHẬN LỜI MỜI

<strong>User</strong>
${invitation.full_name} - ${invitation.phone}
-
<strong>Tham gia shop</strong>
${invitation.shop.code} - ${invitation.shop.name}
-`;

    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

  async userHotlineRequest(user, shop) {
    const fastBuyGroupId = this.groupProdIds.userHotlineRequest;
    const msg = `
USER MUA HOTLINE

<strong>User</strong>
${user.full_name} - ${user.phone}
-
<strong>Chủ shop</strong>
${shop.code} - ${shop.name}
-`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

  async userConnectZCC(user, shop, refreshToken = false) {
    const fastBuyGroupId = this.groupProdIds.connectZCC;
    let tilte = refreshToken ? 'USER LÀM MỚI TOKEN' : 'USER LIÊN KẾT OA';
    const msg = `
${tilte}

<strong>User</strong>
${user.full_name} - ${user.phone}
-
<strong>Chủ shop</strong>
${shop.code} - ${shop.name}
-`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

  //ADMIN TELEGRAM MESSAGE

  sendMessage(fastBuyGroupId, msg, enableHTML = true) {
    msg += `
Admin: ${this.auth.snapshot.user.email}`;
    this.telegramApi.sendMessage(fastBuyGroupId, msg, enableHTML);
  }

  async updateFfmMessage(content) {
    const fastBuyGroupId = this.groupProdIds.adminUpdateFfmInfo;
    let msg = `
ĐỔI THÔNG TIN
`;
    let updatedFields = "";
    for (let field of content.updated_fields) {
      if (field.checked) {
        updatedFields += `
- ${field.type}:
${field.old_value} → <strong>${field.new_value}</strong>
`;
      }
    }
    if (updatedFields.length) {
      msg += `${updatedFields}`;
    }
    msg += `
Ghi chú: ${content.admin_note}

-

NVC: <strong>${content.carrier}</strong>
Mã đơn: <strong>${content.shipping_code}</strong>
Shop: <strong>${content.shop_name}</strong>`;

    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

  async changeUserCredential(editUser, oldUser) {
    const fastBuyGroupId = this.groupProdIds.adminUpdateUser;
    let msg = `
<strong>ĐỔI THÔNG TIN USER</strong>

User: <strong>${oldUser.full_name}</strong>
📧 <strong>${oldUser.email}</strong>
📱 <strong>${oldUser.phone}</strong>
`;
    if (editUser.isUpdateEmail) {
      msg += `
Email mới: ${editUser.email}`
    }
    if (editUser.isUpdatePhone) {
      msg += `
Số điện thoại mới: ${editUser.phone}`
    }
    if (editUser.isUpdatePassword) {
      msg += `
Đổi mật khẩu mới: ✓`
    }
    msg += `
`;
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

  async updateShippingState(
    ffm: Fulfillment,
    actual_compensation_amount: number,
    admin_note: string
  ) {
    const fastBuyGroupId = this.groupProdIds.adminUpdateFfmInfo;
    const msg = `
XÁC NHẬN ĐƠN BỒI HOÀN

Shop: <strong>${ffm.shop?.name || '-'}</strong>
Mã giao hàng: <strong>${ffm.shipping_code}</strong>
Nhà vận chuyển: <strong>${ffm.carrier_display.toUpperCase()}</strong>

COD: <strong>${this.number.transform(ffm.total_cod_amount)}đ</strong>
Tổng phí: <strong>${this.number.transform(ffm.shipping_fee_shop)}đ</strong>
Giá trị bồi hoàn: <strong>${this.number.transform(actual_compensation_amount)}đ</strong>

Ghi chú: ${admin_note}`;

    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

  async newSubcription(shop, subscriptionPlan: SubscriptionPlan, user) {
    const fastBuyGroupId = this.groupProdIds.adminNewSubscription;
    let msg = `
<strong>ADMIN TẠO SUBSCRIPTION</strong>

PLAN
<strong>${subscriptionPlan.name} - ${this.number.transform(subscriptionPlan.price)}đ </strong>
—————————
USER
Họ tên: <strong>${user.full_name} </strong>
Số điện thoại: <strong>${user.phone}</strong>
Email: ${user.email}`;
    msg += `
—————————
Chủ shop: <strong>${shop?.code} - ${shop?.name}</strong>`;

    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async confirmMtShippingExternal(transactions: MoneyTransactionShippingExternal[]) {
    const fastBuyGroupId = this.groupProdIds.adminTransaction;

    const message = transactions.reduce((a, b) => {
      return a + `<strong>${b.carrier_display.toUpperCase()}</strong>: ${b.code}
Giá trị: <strong>${this.number.transform(b.total_cod)}đ</strong>
Ngày tạo: ${moment(b.created_at).format('DD/MM/yyyy')}
Ngày thanh toán: ${moment(b.external_paid_at).format('DD/MM/yyyy')}
-
`;
    }, '');
    const sum_total_cod = transactions.reduce((a, b) => a + Number(b.total_cod), 0);
    const msg = `
XÁC NHẬN PHIÊN NVC
-
${message}
TỔNG: <strong>${this.number.transform(sum_total_cod)}đ</strong>`;
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async splitMtShippingExternalMessage(transaction: MoneyTransactionShippingExternal) {
    const fastBuyGroupId = this.groupProdIds.adminTransaction;

    const msg = `
TÁCH PHIÊN NVC

- ${transaction?.code}
- ${transaction?.carrier_display}: <strong>${this.number.transform(transaction?.total_cod)}đ</strong>`;
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async confirmMtShop(transaction: MoneyTransactionShop) {
    const fastBuyGroupId = this.groupProdIds.adminTransaction;

    let message = '';
    const shop = transaction.shop;
    if (shop) {
      message = `
Shop: <strong>${shop.name}</strong> - ${shop.phone}`;
      if (shop.bank_account) {
        message += `
Ngân hàng: ${shop.bank_account.branch}
Chủ tài khoản: ${shop.bank_account.account_name}
Số tài khoản: ${shop.bank_account.account_number}`;
      }
    }

    const msg = `
XÁC NHẬN PHIÊN SHOP
${message}

Số tiền: <strong>${(this.number.transform(transaction.total_amount))}đ</strong>`;
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async confirmMtTransfer(transaction: MoneyTransactionTransfer) {
    const fastBuyGroupId = this.groupProdIds.adminTransaction;

    const message = `
<strong>${transaction.note}</strong>

${transaction.valid_bang_ke_lines.map(bk => `- ${bk.shop_name}: <strong>${this.number.transform(bk.total_amount)}đ</strong>`).join('\n')}`;

    const msg = `
XÁC NHẬN PHIÊN CHUYỂN KHOẢN
${message}

TỔNG: <strong>${this.number.transform(transaction.total_amount)}đ</strong>`;
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async updateShipmentService(shipmentService: ShipmentService, creates: number, updates: number, deletes: number) {
    const fastBuyGroupId = this.groupProdIds.adminShipmentPrice;

    const message = `
CẬP NHẬT DỊCH VỤ <strong>${shipmentService?.name}</strong> - NVC: <strong>${shipmentService?.provider_name}</strong>:

Thêm: ${creates} cấu hình
Cập nhật: ${updates} cấu hình
Xoá: ${deletes} cấu hình`;

    try {
      await this.sendMessage(fastBuyGroupId, message);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async updateShipmentPriceList(shipmentPriceList: ShipmentPriceList, creates: number, updates: number, deletes: number) {
    const fastBuyGroupId = this.groupProdIds.adminShipmentPrice;

    const message = `
CẬP NHẬT BẢNG GIÁ <strong>${shipmentPriceList?.name}</strong> - NVC: <strong>${shipmentPriceList?.provider_name}</strong>:

Thêm: ${creates} cấu hình
Cập nhật: ${updates} cấu hình
Xoá: ${deletes} cấu hình`;

    try {
      await this.sendMessage(fastBuyGroupId, message);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async updateShopShipmentPriceList(
    shop: Shop, requests: Array<{ connection: Connection, shipmentPriceList: ShipmentPriceList }>
  ) {
    const fastBuyGroupId = this.groupProdIds.adminShipmentPrice;

    const message = `
SET BẢNG GIÁ <strong>${shop.code} - ${shop.name}</strong>

${requests.map(req => `- ${req.connection.name.toUpperCase()}: <strong>${req.shipmentPriceList.name}</strong>`).join('\n')}`;

    try {
      await this.sendMessage(fastBuyGroupId, message);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async updateFfmShippingFees(fulfillment: Fulfillment, connection: Connection) {
    const fastBuyGroupId = this.groupProdIds.adminShipmentPrice;

    const message = `
UPDATE CƯỚC PHÍ FFM <strong>${fulfillment.shipping_code} | ${connection.name.toUpperCase()}</strong>

${fulfillment.shipping_fee_shop_lines.map(fee => `- ${fee.shipping_fee_type_display}: <strong>${this.number.transform(fee.cost)}đ</strong>`).join('\n')}

Shop: <strong>${fulfillment.shop.code} - ${fulfillment.shop.name}</strong>`;

    try {
      await this.sendMessage(fastBuyGroupId, message);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async addShippingFees(fulfillment: Fulfillment, connection: Connection, feeType: FeeType) {
    const fastBuyGroupId = this.groupProdIds.adminShipmentPrice;

    const message = `
TÍNH ${SHIPPING_FEE_SHOP_TYPE[feeType].toUpperCase()} FFM <strong>${fulfillment.shipping_code} | ${connection.name.toUpperCase()}</strong>

Phí sau khi tính ${SHIPPING_FEE_SHOP_TYPE[feeType]}:
${fulfillment.shipping_fee_shop_lines.map(fee => `- ${fee.shipping_fee_type_display}: <strong>${this.number.transform(fee.cost)}đ</strong>`).join('\n')}

Shop: <strong>${fulfillment.shop.code} - ${fulfillment.shop.name}</strong>`;

    try {
      await this.sendMessage(fastBuyGroupId, message);
      debug.log("SENT MESSAGE");
    } catch (e) {
      debug.error("ERROR SEND MESSAGE");
    }
  }

  async updateShopMtRruleMessage(shop: Shop) {
    const fastBuyGroupId = this.groupProdIds.shopMoneyTransactionRrule;

    const msg = `
<strong>ADMIN</strong> CẬP NHẬT

<strong>${shop.code} - ${shop.name}</strong> - ${MONEY_TRANSACTION_RRULE[shop.money_transaction_rrule]}`;

    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateShopPriorityMtMessage(shop: Shop) {
    const fastBuyGroupId = this.groupProdIds.shopMoneyTransactionRrule;

    const msg = `
${shop?.is_prior_money_transaction ? 'HUỶ ƯU TIÊN SHOP' : 'CẬP NHẬT SHOP ƯU TIÊN'}

<strong>${shop.code} - ${shop.name}</strong>`;

    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateSales(user, refAff, refSale) {
    const fastBuyGroupId = this.groupProdIds.adminUpdateSales;
    const msg = `
GÁN REF USER <strong>${user?.phone || '-'} - ${user?.full_name || '-'}</strong>

-Sales: <strong>${refSale?.phone || '-'} - ${refSale?.full_name || '-'}</strong>
-Aff: <strong>${refAff?.phone || '-'} - ${refAff?.full_name || '-'}</strong>`

    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateMinShopBalanceShopSetting(shop, shopBalanceShopSetting: MinShopBalanceSetting) {
    const fastBuyGroupId = this.groupProdIds.adminUpdateFfmInfo;
    const msg = `
ĐỔI HẠN MỨC TẠO ĐƠN

Shop: <strong>${shop?.code} - ${shop?.name}</strong>

- Điều kiện tạo đơn: <strong>${shopBalanceShopSetting.balance_type == 'actual' ? 'Số dư thực tế' : shopBalanceShopSetting.balance_type == 'expected' ? 'Số dư dự kiến' : 'Hạn mức nợ'}</strong>
- Số dư tối thiểu: <strong>${this.moneyFormat(shopBalanceShopSetting.min_shop_balance || 0)}</strong>
`

    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async newTenant(tenant: Tenant, user: User, shop: Shop) {
    const fastBuyGroupId = this.groupProdIds.adminTenant;
    let infoMsg: string = `
<strong>User</strong>
${user.full_name} - ${user.phone}`;

    if(shop) {
      infoMsg = infoMsg + `

-
<strong>Shop</strong>
${shop.name} - ${shop.code}
${shop.phone}`;
    }
    
    const msg = `
ADMIN TẠO TENANT
-
${infoMsg}
-
<strong>Tenant</strong>
Name: ${tenant.name}
Domain: ${tenant.domain}
-
`
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async activateTenant(user, tenant, hotline) {
    const fastBuyGroupId = this.groupProdIds.adminTenant;
    const msg = `
ADMIN KÍCH HOẠT TENANT
-
<strong>User</strong>
${user.full_name} - ${user.phone}
-
<strong>Tenant</strong>
Name: ${tenant.name}
Domain: ${tenant.domain}
-
<strong>Hotline</strong>
${hotline.hotline} - ${hotline.name}
(is_free_charge = ${hotline.is_free_charge})
`
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async newCredit(user, credit) {
    const fastBuyGroupId = credit?.classify == 'shipping' ? this.groupProdIds.adminNewShippingCredit : this.groupProdIds.adminNewTelecomCredit;
    const msg = `
ADMIN XÁC NHẬN CREDIT
-
<strong>User</strong>
${user.full_name} - ${user.phone}
-
<strong>Credit</strong>
Loại: <strong>${credit?.classify == 'shipping' ? 'Vận chuyển' : 'Viễn Thông'}</strong>
Giá trị: <strong>${this.number.transform(credit?.amount)}đ</strong>
-
`
    try {
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async updateUserSettings(user, type: EXTENSION_CHARGE_TYPE) {
    const fastBuyGroupId = this.groupProdIds.adminTenant;
    const msg = `
ADMIN CONFIG CÁCH TÍNH PHÍ
-
<strong>User</strong>
${user.full_name} - ${user.phone}
-
Phí máy nhánh: ${AdminUserApi.extensionChargeTypeMap(type)}
-`
    try{
      await this.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch(e){
      debug.log('ERROR SEND MESSAGE');
    }
  }

  async deleteShopMessage(
    user,
    shop,
    extentionCount,
    callLogCount,
    invoiceCount,
    transactionCount,
    staffCount,
    invitationCount
    ) {
    const fastBuyGroupId = this.groupProdIds.deleteShop;
    const msg = `
USER REMOVE SHOP

<strong>User</strong>
${user.full_name} - ${user.phone}
-
<strong>Remove shop</strong>
${shop.code} - ${shop.name}
-
Extention: ${extentionCount}
Call log: ${callLogCount}
-
Invoice: ${invoiceCount}
Transaction: ${transactionCount}
-
Staff: ${staffCount}
Invitation: ${invitationCount}
-

`;
    try {
      await this.telegramApi.sendMessage(fastBuyGroupId, msg);
      debug.log('SENT MESSAGE');
    } catch (e) {
      debug.log("ERROR SEND MESSAGE");
    }
  }

}
