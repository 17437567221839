<div class="hold-transition wrapper-login">
  <div class="login-box">
    <div class="login-logo">
      <img style="width: 80px" src="/assets/images/LOGO_ITS.png" alt="">
    </div>
    <div class="login-box-body p-0">
      <form role="form" class="form-horizontal p-0" #form="ngForm">
        <div class="p-3 m-1 text-center">
          <span class="font-weight-bold">Đặt lại mật khẩu</span>
        </div>
        <div class="p-3 border-top">
          <div class="form-group row">
            <label for="password" required class="col-sm-4 control-label mb-0 align-self-center">
              Mật khẩu mới
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input id="password" name="password" type="password" class="form-control" placeholder="Mật khẩu mới"
                     [(ngModel)]="resetPasswordData.password"  autofocus
                     (keyup.enter)="onEnterLoginInput()" />
            </div>
          </div>
          <div class="form-group row mb-0">
            <label required class="col-sm-4 control-label mb-0 align-self-center">
              Nhập lại mật khẩu mới
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input #confirmPasswordInput  type="password" required name="confirm" class="form-control" placeholder="Nhập lại mật khẩu mới"
                     [(ngModel)]="resetPasswordData.confirm" />
            </div>
          </div>
          <div *ngIf="error" class="mt-2">
            <small class="text-danger">
              {{errorMessage}}
            </small>
          </div>
        </div>
        <div class="p-3 border-top d-flex justify-content-between flex-row-reverse">
          <button class="btn btn-primary" (click)="doResetPassword()" [disabled]="!form.form.valid || loading">
            <i class="fa fa-circle-o-notch fa-spin mr-1" aria-hidden="true" style="font-size: 1rem" *ngIf="loading"></i>
            Đặt lại mật khẩu
          </button>
          <button class="btn btn-primary btn-outline" routerLink="/login">
            <i class="fa fa-sign-in mr-1" aria-hidden="true"></i>
            Đăng nhập
          </button>
        </div>
      </form>
    </div>
    <!-- /.register-box-body -->
  </div>
</div>
