import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@etop/core';
import { StringHandler } from '@etop/utils';

@Injectable({
  providedIn: 'root'
})
export class TelegramApi {

  constructor(
    private http: HttpClient,
    private appConfig: ConfigService,
  ) {}

  sendMessage(fastBuyGroupId, msg, enableHTML = true) {
    if (!!this.appConfig.getConfig()?.disable_telegram_bot) return;
    const info = `\nSource: ${window.location.hostname} - ${
      window.innerWidth
    }px - ${navigator.platform}`;
    msg += info;
    //token eb2b_dev_bot
    let token = '7442707840:AAFU5Ysp7aU_DZK7g3GWUFo_NVgwKGkkGEI';
    const fastBuyUrl = `https://api.telegram.org/bot${token}/sendMessage`;
    const fastbuyData: any = {
      chat_id: fastBuyGroupId,
      text: StringHandler.parseHtmlSpecialCharacters(msg),
    };
    if (enableHTML) {
      fastbuyData.parse_mode = 'HTML';
    }

    return this.http.post(fastBuyUrl, fastbuyData).toPromise();
  }

  sendPhoto(fastBuyGroupId, photo, caption?) {
    if (!!this.appConfig.getConfig()?.disable_telegram_bot) return;
    //token eb2b_dev_bot
    // let token = '5401414195:AAHywUnX7xGR46CnpTwKXCSqhTIrXEZrmC8';
    // if (!!this.appConfig.getConfig()?.is_production) {
    //   //token eb2b_bot
    //   token = '5308699479:AAH-E_2Zse9JMmVzCHhINCZPlq3JOGiAgeY';
    // }
    // const fastBuyUrl = `https://api.telegram.org/bot${token}/sendPhoto`;
    // const fastbuyData: any = {
    //   chat_id: fastBuyGroupId,
    //   photo: photo.toString(),
    //   caption: caption,
    //   parse_mode: 'HTML'
    // };

    // return this.http.post(fastBuyUrl, fastbuyData).toPromise();
  }

}
