import {Injectable} from '@angular/core';
import {HttpService} from "@etop/common";
import {User, UserSource} from "libs/models";
import {Paging} from "@etop/shared";

const SOURCE = {
  'unknown': 'Không xác định',
  'psx': 'PSX',
  'etop': 'its',
  'topship': 'TOPSHIP',
  'ts_app_android': 'TS App Android',
  'ts_app_ios': 'TS App iOS',
  'ts_app_web': 'TS App Web',
  'partner': 'Partner',
  'etop_app_android': 'eTop App Android',
  'etop_app_ios': 'eTop App iOS',
  'telecom': 'its',
}

export enum EXTENSION_CHARGE_TYPE {
  prepaid = 'prepaid',
  postpaid = 'postpaid',
  free = 'free'
}

const EXTENSION_CHARGE = {
  'prepaid': 'TRẢ TRƯỚC',
  'postpaid': 'TRẢ SAU',
  'free': 'MIỄN PHÍ'
}

@Injectable({
  providedIn: 'root'
})
export class AdminUserApi {

  constructor(
    private http: HttpService
  ) {
  }

  static sourceMap(source: UserSource) {
    return SOURCE[source];
  }

  static extensionChargeTypeMap(type: EXTENSION_CHARGE_TYPE) {
    return EXTENSION_CHARGE[type];
  }

  static userMap(user: User): User {
    return {
      ...user,
      source_display: AdminUserApi.sourceMap(user.source)
    };
  }

  updateUserRef(query: AdminUserAPI.UpdateUserRef) {
    return this.http
      .post('api/admin.User/UpdateUserRef', query)
      .toPromise();
  }

  changeUserCredential(request: AdminUserAPI.ChangeUserCredential) {
    return this.http
      .post('api/admin.User/ChangeUserCredential', request)
      .toPromise();
  }

  getUser(id: string) {
    return this.http
      .post('api/admin.User/GetUser', {id})
      .toPromise()
      .then(res => AdminUserApi.userMap(res));
  }

  getUsers(query: AdminUserAPI.GetUsersRequest) {
    return this.http
      .post('api/admin.User/GetUsers', query)
      .toPromise()
      .then(res => res.users.map(s => AdminUserApi.userMap(s)));
  }

  getUsersByIDs(ids: string[]) {
    return this.http
      .post('api/admin.User/GetUsersByIDs', {ids})
      .toPromise()
      .then(res => res.users.map(u => AdminUserApi.userMap(u)));
  }

  blockUser(user_id: string, block_reason: string) {
    return this.http
      .post('api/admin.User/BlockUser', {user_id, block_reason})
      .toPromise();
  }

  unblockUser(user_id: string) {
    return this.http
      .post('api/admin.User/UnblockUser', {user_id})
      .toPromise();
  }

  getUserSettings(request: AdminUserAPI.GetUserSettingsRequest) {
    return this.http
      .post('api/admin.Etelecom/GetUserSettings', request)
      .toPromise()
      .then(res => res.user_settings);
  }

  updateUserSetting(extension_charge_type: EXTENSION_CHARGE_TYPE, user_id: string) {
    return this.http
      .post('api/admin.Etelecom/UpdateUserSetting', { extension_charge_type, user_id })
      .toPromise();
  }

  getAccountUsers(account_id: string) {
    return this.http
      .post('api/admin.AccountUser/GetAccountUsers', {filter: {account_id}})
      .toPromise()
      .then(res => res.account_users);
  }

  getLatestUserOTP(user_id: string, phone?: string) {
    return this.http
      .post('api/admin.User/GetLatestUserOTP', {phone, user_id})
      .toPromise()
      .then(res => res);
  }

}

export namespace AdminUserAPI {
  export interface GetUserFilters {
    created_at?: {
      from?: string;
      to?: string;
    };
    name?: string;
    phone?: string;
    email?: string;
  }

  export interface GetUsersRequest {
    filters?: GetUserFilters;
    paging: Paging;
  }

  export interface GetUserSettingsRequest {
    user_ids: Array<string>;
    paging?: Paging;
  }

  export interface UpdateUserRef {
    ref_aff?: string;
    ref_sale?: string;
    user_id: string;
  }
  export interface ChangeUserCredential {
    email?: string;
    password?: string;
    phone?: string;
    user_id: string;
  }
}
